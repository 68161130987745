import React from 'react';
import IgniterLogo from '!svg-react-loader?name=IgniterLogo!images/igniter/logo.svg';
import IgniterBug from '!svg-react-loader?name=IgniterBug!images/igniter/producers/logos/igniter-media-bug.svg';
import CheckIcon from '!svg-react-loader?name=CheckIcon!images/igniter/svgs/check.svg';
import UIButtonClose from '!svg-react-loader?name=UIButtonClose!images/igniter/svgs/times-circle.svg';
import { moveCursorToInputEnd } from 'Utils/FormUtils'

export class NavbarSearchUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };

    this.triggerLink = document.querySelector(this.props.triggerSelector);
    this.handleWidgetTriggered = this.handleWidgetTriggered.bind(this);
    this.handleSearchInputKeyDown = this.handleSearchInputKeyDown.bind(this);
    this.handleSearchInputKeyUp = this.handleSearchInputKeyUp.bind(this);
    this.onProductTypeLinkClicked = this.onProductTypeLinkClicked.bind(this);
  }

  componentDidMount() {
    new Foundation.DropdownMenu($('.product-type-selector > ul:first-child'));
    this.triggerLink.addEventListener('click', this.handleWidgetTriggered);
  }

  componentWillUnmount() {
    this.triggerLink.removeEventListener('click', this.handleWidgetTriggered);
  }

  handleWidgetTriggered() {
    this.setState((prevState, props) => {
      const newVisibility = !prevState.visible;

      this.triggerLink.dispatchEvent(
        new CustomEvent(
          'NavbarSearchWidget:triggered', {
            bubbles: true,
            cancelable: true,
            detail: { visible: newVisibility }
          }
        )
      );

      if (newVisibility) {
        $('.search-input-block').addClass('is-animating');
        this.searchInput.focus();
      }

      return { visible: newVisibility };
    });
  }

  handleSearchInputKeyDown(e) {
    const { handleAutocompleteSelectionChange: moveAutocompleteSelection } = this.props;

    switch (e.keyCode) {
      case 38: // up
        moveAutocompleteSelection(-1);
        e.preventDefault();
        break;
      case 40: // down
        moveAutocompleteSelection(1);
        e.preventDefault();
        break;
      default:
        break;
    }

    return false;
  }

  handleSearchInputKeyUp(e) {
    const {
      resetSearchWidget,
      executeSearch,
    } = this.props;

    switch (e.key) {
      case 'Escape':
        resetSearchWidget();
        this.handleWidgetTriggered();
        break;
      case 'Enter':
        executeSearch();
        break;
      default:
        break;
    }
  }

  onProductTypeLinkClicked(event) {
    const {
      onProductTypeChange,
    } = this.props;

    const target = $(event.target);
    event.stopPropagation();

    const syntheticEvent = {
      target: {
        value: target.data('type-id'),
      },
    };

    onProductTypeChange(syntheticEvent);
    this.searchInput.focus();
    moveCursorToInputEnd(this.searchInput);
    return false;
  }

  render() {
    const {
      q,
      selectedProductType,
      siteProductTypes,
      onSearchInputChange,
      suggestions,
    } = this.props;

    const renderProductTypeDropdown = () => {
      return (
        <ul className="dropdown menu" data-dropdown-menu data-alignment="right">
          <li className="is-dropdown-submenu-parent">
            <a href="#">{productTypeLabel()}</a>
            <ul className="menu">
              <li key="0">
                <a href="#" className={isProductTypeSelected('all') ? 'selected' : ''} onClick={this.onProductTypeLinkClicked} data-type-id="all">{isProductTypeSelected('all') ? <CheckIcon /> : ''}All Products</a>
              </li>
              {siteProductTypes.map((type, i) => (
                <li key={i+1}>
                  <a href="#" className={isProductTypeSelected(type.id) ? 'selected' : ''} onClick={this.onProductTypeLinkClicked} data-type-id={type.id}>{isProductTypeSelected(type.id) ? <CheckIcon /> : ''}{type.name}</a>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      );
    };

    const productTypeLabel = () => {
      const productTypeId = this.props.selectedProductType;

      if (productTypeId === 'all')
        return 'All Products';
      else
        return siteProductTypes.find((type) => type.id == productTypeId).name;
    };

    const isProductTypeSelected = (typeId) => {
      return (typeId == this.props.selectedProductType);
    };

    const renderSuggestion = (suggestion) => (
      <a href={suggestion.url}>{suggestion.text}</a>
    );

    const renderAutocompleteSuggestions = (suggestions) => {
      const isSuggestionSelected = (idx) => {
        return (idx == this.props.selectedAutocompleteSuggestion) ? 'selected' : '';
      };

      return (
        <div className="autocomplete-block">
          <div className="autocomplete-container grid-x">
            <div className="cell auto">
              <p className="about-suggestions">Quick links</p>
              <ul className="suggestion-list no-bullet">
                {suggestions.map((s, idx) => {
                  return <li key={idx} className={isSuggestionSelected(idx)}>{renderSuggestion(s)}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="search-widget grid-y">
        <div className="cell grid-x align-middle row-1">
          <div className="logo-container shrink cell">
            <a className="logo" href="/"><IgniterBug /><IgniterLogo /></a>
          </div>
          <div className="auto cell grid">
            <div className="search-input-block">
              <div className="search-input-container grid-x align-middle">
                <i className="fa fa-search cell small-1"></i>
                <input className="cell auto"
                  name="q"
                  type="text"
                  ref={input => this.searchInput = input}
                  placeholder="I'm searching for..."
                  autoComplete="off"
                  onKeyDown={this.handleSearchInputKeyDown}
                  onKeyUp={this.handleSearchInputKeyUp}
                  onChange={onSearchInputChange}
                  value={q} />
              </div>
            </div>
          </div>
          <div className="sep product-type-selector shrink cell">
            {renderProductTypeDropdown()}
          </div>
          <div className="shrink cell">
            <a className="closer" href="#" onClick={this.handleWidgetTriggered}><UIButtonClose /></a>
          </div>
        </div>
        <div className="cell grid-x align-top row-2">
          {/** NOTE: This 2nd row (.row-2) needs to be made up of the same cells that are part of
                     the 1st row (.row-1), with the class .snapper applied to the cells that won't
                     be displayed. They are merely placed in this row for layout parity with the
                     fluid cells in the row above. **/}
          <div className="logo-container shrink cell snapper">
            <a className="logo" href="/"><IgniterBug /><IgniterLogo /></a>
          </div>
          <div className="auto cell" style={{display: suggestions.length > 0 ? 'block' : 'none'}}>
            {renderAutocompleteSuggestions(suggestions)}
          </div>
          <div className="sep product-type-selector shrink cell snapper">
            {renderProductTypeDropdown()}
          </div>
          <div className="shrink cell snapper">
            <a className="closer" href="#" onClick={this.handleWidgetTriggered}><UIButtonClose /></a>
          </div>
        </div>
      </div>
    );
  }
}
