var React = require('react');

function EmailIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"id":"Path_7803","data-name":"Path 7803","className":"primary-fill","d":"M24.483,10.493V5.246L19.237,0H3.5V10.493l-3.5,7V27.98H27.98V17.488ZM3.5,14.4v3.084H1.955Zm13.99,8.331h-7L7,17.488H5.246V1.749H18.512L22.734,5.97V17.488H20.985Zm7-5.246V14.4l1.542,3.084ZM15.739,5.246H7V3.5h8.744Zm3.5,3.5H7V7H19.237ZM7,10.493h13.99v1.749H7Zm0,3.5h13.99v1.749H7Z"}));
}

EmailIcon.defaultProps = {"id":"inbox-with-mail","width":"27.98","height":"27.98","viewBox":"0 0 27.98 27.98"};

module.exports = EmailIcon;

EmailIcon.default = EmailIcon;
